import {
  IND_ONE,
  PATH,
  ADMIN_URL,
  IND_ONE_SIT,
  PRODUCT_IP,
} from '../../Constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as UserActionType from './userActionTypes';
import { redirectToLogin } from '../Common/CommonAction';
import session from 'redux-persist/lib/storage/session';

var token = sessionStorage.getItem('token');
var admin_email = sessionStorage.getItem('name');
var e_id = parseInt(sessionStorage.getItem('e_id'));
export const createJsonHeader = (token) => {
  return {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v3.0',
    },
  };
};
export const PasswordUpdate = async (state) => {
  try {
    const aadhardata = await axios.post(
      `${IND_ONE}${PATH.changepassword}`,
      state,
      createJsonHeader(token)
    );
    console.log(aadhardata);
    if (aadhardata.status === 201) {
      toast.success('Successfully changed password!.');
    } else if (aadhardata.status === 400) {
      console.log(aadhardata);
    }
  } catch (error) {
    console.log(error);
    console.log(error.response);
    if (
      error.response &&
      error.response.data.old_password &&
      error.response.data.old_password[0] === 'Wrong password.'
    ) {
      toast.error(error.response.data.old_password[0]);
    } else if (
      error.response &&
      error.response.data.password &&
      error.response.data.password[0] ===
      'Password must be confirmed correctly.'
    ) {
    }
  }
};

export const ForgetPassword = async (state, cb) => {
  console.log(state);
  const data = {
    email: state,
  };
  sessionStorage.setItem('forgetemail', state);
  try {
    const aadhardata = await axios.post(
      `${IND_ONE}${PATH.passwordreset}`,
      data
    );
    console.log(aadhardata);
    if (aadhardata.status === 200) {
      //   dispatch({ type: "FORGET_EMAIL", payload: state });
      toast.success('Successfully Sent Email!... ');
      cb(true);
    } else if (aadhardata.status === 400) {
      console.log(aadhardata.response);
      cb(false);
    }
  } catch (error) {
    // console.log(error.response.data.email[0]);
    if (error && error.response && error.response.data.email) {
      toast.error(error.response.data.email[0]);
    } else {
      toast.error('OOPS!.. Something went Wrong');
    }
    cb(false);
  }
};
export const ConfirmeResetPassword = async (state, passwordkey, history) => {
  const data = {
    password: state.password,
    token: passwordkey,
  };
  sessionStorage.setItem('forgetpassword', state.password);
  try {
    const aadhardata = await axios(`${IND_ONE}${PATH.passwordresetconfirm}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    });
    console.log(aadhardata);
    if (aadhardata.status === 200) {
      //   dispatch({ type: "USER_PASSWORD", payload: state.password });
      toast.success('You have successfully changed password!..');
      userLogin(state, history);
    }
  } catch (error) {
    if (error.response.status === 400 && error.response.data) {
      toast.error(error.response.data.password[0]);
    } else if (error.response.status === 404) {
      toast.error('Forget Password link is expired.Please try again!..');
    }
    console.log(error.response);
  }
};

export const GettingCount = async (state, cb) => {
  const data1 = {
    email: state.email,
  };
  try {
    axios(`${IND_ONE}${PATH.wrong_pass_return}`, {
      method: 'POST',
      data: data1,
    }).then((res) => {
      if (res.status === 200) {
        cb(res.data, true);
      }
      console.log(res);
    });
  } catch (error) {
    if (error && error.response) {
      cb(false);
      alert(error.response.data.message);
    }
  }
};
export const UpdateCount = async (state, count, cb) => {
  const inccount = count + 1;
  const data1 = {
    email: state.email,
    upd_count: inccount,
  };
  try {
    axios(`${IND_ONE}${PATH.wrong_pass_update}`, {
      method: 'POST',
      data: data1,
    }).then((res) => {
      console.log(res);
    });
  } catch (error) {
    if (error && error.response) {
      alert(error.response.data.message);
    }
  }
};

export const userLogin = async (state, history, cb, errorcallback) => {
  //   const { forgetemail, userpassword } = getState().userdetails;
  const forgetpassword = sessionStorage.getItem('forgetpassword');
  const forgetemail = sessionStorage.getItem('forgetemail');
  const data = {
    email: state.email || forgetemail,
    password: state.password || forgetpassword,
  };
  try {
    const login = await axios.post(`${IND_ONE}${'users/token/'}`, data);
    console.log(login);
    if (login.status === 200) {
      console.log(login.data.access);
      sessionStorage.setItem('token', login.data.access);
      sessionStorage.setItem('name', data.email);
      // dispatch({
      //   type: UserActionType.REVIEW_DASHBOARD,
      //   payload: maindata,
      // });

      // if (state.password.length > 8) {
      //   history("/Products");
      // }
      // history("/payslip");
      // window.location.href = "/";
      if (forgetemail && forgetpassword) {
        window.location.href = '/';
      }

      cb(true);
    } else if (login.status === 400) {
      console.log(login);
      cb(false);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.detail === 'Password is incorrect') {
        cb(true);
      } else if (error.response.status === 401) {
        // redirectToLogin();
        cb(false);
        if (error.response.data?.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error(error.response.data?.message);
        }
      }
    }
  }
};

export const isAdmin = () => async (dispatch) => {
  let token = sessionStorage.getItem('token');
  console.log("token",token)
  const args = {
    method: 'GET',
    url: `${PRODUCT_IP}${PATH.isAdmin}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
  };
  try {
    let api = await axios(args);
    console.log("isAdmin",api)
    if (api.status == 200) {
      // dispatch({
      //   type: UserActionType.IS_ADMIN,
      //   payload: JSON.parse(api.data.is_admin),
      // });
      if (api?.data?.is_admin === true) {
        sessionStorage.setItem('type', 'admin');
        sessionStorage.setItem('e_id',api?.data?.e_id);
      } else {
        sessionStorage.setItem('type', 'processor');
        sessionStorage.setItem('e_id',api?.data?.e_id);
      }
      console.log(api.data, 'api.data.is_admin');
      // alert(JSON.stringify(api.data))
      return api?.data?.is_admin;
    } else {
      // toast.error('Something Went Wrong');
      toast.error(api?.data?.message);
      return false;
    }
  } catch (error) {
    console.log("error",error);
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      if (error.response.status === 500) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const Registration = async (values, cb) => {
  const { firstName, lastName, password, phoneNumber, email } = values;
  const data = {
    first_name: firstName,
    last_name: lastName,
    password: password,
    email: email,
    phone: phoneNumber,
    application: 'credit',
  };

  try {
    const registration = await axios.post(
      `${IND_ONE}${PATH.Registartion}`,
      data
    );
    console.log(registration);

    if (registration.status === 201 || registration.status === 200) {
      toast.success(registration.data.message);
      cb(true);
    } else {
      toast.error('Registration Failed! ,Try again after sometime.');
      cb(false);
    }
  } catch (error) {
    cb(false);
    console.log(error.response);
    if (error && error.response) {
      toast.error(error.response.data.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
    } else {
      toast.error('Registration Failed!..');
    }
  }
};

export const userLogout = () => async (dispatch, getState) => {
  dispatch({ type: 'LOGOUT' });
};

export const ReviewDashboard = (page_no = 1, rows_per_page = 10) => async (dispatch, getState) => {
  const data1 = {
    //user_email: admin_email,
    // "geetha.kannan@in-d.ai",
    page_no,
    rows_per_page,
    e_id
  };
  let args = {
    url: `${PRODUCT_IP}${PATH.reviewStatus}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data1,
  };

  try {
    let api = await axios(args);
    if (api.status === 200) {
      let maindata = api.data.result.Batches.map((each) => [
        each.id,
        //each.batch_id,
        each.batch_name,
        each.channel,
        each.received_at,
        each.status,
        each.modified_by,
        each.modified_at,
        each.obligor,
        each.warehouse,
        each.queue_name,
      ]);
      sessionStorage.setItem('total_count', api.data.result.Total_Batches);
      dispatch({
        type: UserActionType.REVIEW_DASHBOARD,
        payload: maindata,
      });
      console.log(api.data.result);
      return true;
    }
  } catch (error) {
    // console.log('errorororr', error.response);
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const PendingDashboard = (page_no = 1, rows_per_page = 10) => async (dispatch) => {
  const data1 = {
    //user_email: admin_email,
    page_no,
    rows_per_page,
    e_id
  };
  const args = {
    method: 'POST',
    url: `${PRODUCT_IP}${PATH.pendingstatus}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      console.log(api);
      api.data.result.Batches.map((e) => {
        data.push([
          e.id,
          //e.batch_id,
          e.batch_name,
          e.channel,
          e.received_at,
          //e.modified_at,
          e.status,
          //e.modified_by,
          e.queue_name,
          //e.exported_at
          e.obligor,
          e.warehouse,
        ]);
      });
      sessionStorage.setItem('total_count', api.data.result.Total_Batches);

      dispatch({
        type: UserActionType.PENDING_DASHBOARD,
        payload: data,
      });
      return true;
    } else {
      toast.error('Something Went Wrong');
      dispatch({
        type: UserActionType.PENDING_DASHBOARD,
        payload: [],
      });
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const AdminUploadDashboard =
  (page_no = 1, rows_per_page = 10) =>
    async (dispatch) => {
      const data = {
        //user_email: admin_email,
        page_no,
        rows_per_page,
        e_id
      };
      const args = {
        method: 'POST',
        url: `${ADMIN_URL}${PATH.admin_dashboard}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Accept-Version': 'v1.0',
        },
        data,
      };
      try {
        let api = await axios(args);
        if (api.status == 200) {
          let data = [];
          console.log(api);
          api.data.result.Batches.map((e) => {
            data.push([
              e.batch_name,
              e.uploaded_date_time,
              e.channel,
              e.queue,
              e.assigned_to,
              e.no_of_docs,
              e.status,
              e.id,
            ]);
          });
          sessionStorage.setItem('total_count', api.data.result.Total_Batches);
          dispatch({
            type: UserActionType.ADMIN_UPLOAD_DASHBOARD,
            payload: data,
          });
          return true;
        } else {
          let message =
            api.status === 401 ? api.data.message : 'Something Went Wrong';
          toast.error(message);
          dispatch({
            type: UserActionType.ADMIN_UPLOAD_DASHBOARD,
            payload: [],
          });
          return false;
        }
      } catch (error) {
        if (error && error.response) {
          dispatch({
            type: UserActionType.ADMIN_UPLOAD_DASHBOARD,
            payload: [],
          });
          //toast.error(error?.response?.data?.message);
          if (error.response.status === 401) {
            redirectToLogin();
          }
          return false;
        }
      }
    };

    export const mountListContract = ()=> async(dispatch) => {
      const args = {
        method: 'POST',
        url: `${PRODUCT_IP}${PATH.list_mount_contract}`,
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Accept-Version': 'v1.0',
        },
        data: {e_id},
      };
      try {
        let api = await axios(args);
        console.log(api.data.result.Mount_Files,'listmount');
        if (api.status === 200){
          dispatch({
            type: UserActionType.CONTRACT_LIST,
            payload: api.data.result.Mount_Files,
          });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        if (error && error.response) {
          //toast.error(error?.response?.data?.message);
          if (error.response.status === 401) {
            redirectToLogin();
          }
          return false;
        }
      }
    };
    
    export const mountListLTape = ()=> async(dispatch) => {
      const args = {
        method: 'POST',
        url: `${PRODUCT_IP}${PATH.list_mount_loanTape}`,
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Accept-Version': 'v1.0',
        },
      data: {e_id},
      };
      try {
        let api = await axios(args);
        console.log(api.data.result.Mount_Files,'listmount');
        if (api.status === 200){
          dispatch({
            type: UserActionType.LTAPE_LIST,
            payload: api.data.result.Mount_Files,
          });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        if (error && error.response) {
          //toast.error(error?.response?.data?.message);
          if (error.response.status === 401) {
            redirectToLogin();
          }
          return false;
        }
      }
    };

// * Open cases for admin
export const OpenCasesDashboard =
  (page_no, rows_per_page = 25) =>
    async (dispatch) => {
      const data = {
        // user_email: admin_email,
        page_no,
        rows_per_page,
        e_id
      };
      const args = {
        method: 'POST',
        url: `${PRODUCT_IP}${PATH.admin_dashboard_batch}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Accept-Version': 'v3.0',
        },
        data,
      };
      try {
        let api = await axios(args);
        if (api.status == 200) {
          let data = [];
          console.log(api);
          api.data.result
            .filter((e) => e.status === 'Rejected')
            .map((e) => {
              data.push([
                e.cs_id,
                e.received_at,
                e.channel,
                e.queue_type,
                e.modified_by,
                e.total_documents,
                e.status,
                e.password_files,
                // e.review_flag + e.delete_flag + e.export_flag,
                // e.modified_at,
              ]);
            });
          sessionStorage.setItem('total_count', api.data.total_cases);
          dispatch({
            type: UserActionType.OPEN_CASES_DASHBOARD,
            payload: data,
          });
          return true;
        } else {
          let message =
            api.status === 401 ? api.data.message : 'Something Went Wrong';
          toast.error(message);
          dispatch({
            type: UserActionType.OPEN_CASES_DASHBOARD,
            payload: [],
          });
          return false;
        }
      } catch (error) {
        if (error && error.response) {
          dispatch({
            type: UserActionType.OPEN_CASES_DASHBOARD,
            payload: [],
          });
          // toast.error(error?.response?.data?.message);
          if (error.response.status === 401) {
            redirectToLogin();
          }
          return false;
        }
      }
    };

export const ExportDashboard = (page_no = 1, rows_per_page = 10) => async (dispatch) => {
  const data1 = {
    //user_email: admin_email,
    page_no,
    rows_per_page,
    e_id
  };
  const args = {
    method: 'POST',
    url: `${PRODUCT_IP}${PATH.exportedstatus}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      console.log(api);
      api.data.result.Batches.map((e) => {
        data.push([
          e.id,
          //e.batch_id,
          e.batch_name,
          e.channel,
          e.received_at,
          e.reviewed_by,
          //e.modified_at,
          e.status,
          e.obligor,
          e.warehouse,
          e.queue_name,
        ]);
      });
      sessionStorage.setItem('total_count', api.data.result.Total_Batches);

      dispatch({
        type: UserActionType.EXPORT_STATUS,
        payload: data,
      });
      return true;
    } else {
      toast.error('Something Went Wrong');
      dispatch({
        type: UserActionType.EXPORT_STATUS,
        payload: [],
      });
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const DeleteDashboard = (page_no = 1, rows_per_page = 10) => async (dispatch) => {
  const data1 = {
    //user_email: admin_email,
    // subashini@in-d.ai
    page_no,
    rows_per_page,
    e_id
  };
  const args = {
    method: 'POST',
    url: `${PRODUCT_IP}${PATH.deletestatus}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      let data = [];
      console.log(api);
      api.data.result.Batches.map((e) => {
        data.push([
          e.id,
          // e.batch_id,
          e.batch_name,
          e.channel,
          e.received_at,
          e.reviewed_by,
          //e.modified_at,
          e.status,
          e.obligor,
          e.warehouse,
          e.queue_name,
        ]);
      });
      sessionStorage.setItem('total_count', api.data.result.Total_Batches);

      dispatch({
        type: UserActionType.DELETE_STATUS,
        payload: data,
      });
      return true;
    } else {
      toast.error('Something Went Wrong');
      dispatch({
        type: UserActionType.DELETE_STATUS,
        payload: [],
      });
      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const DeleteDocuments = (job_id) => async (dispatch) => {
  const data1 = { b_ids: job_id, e_id };
  const args = {
    method: 'POST',
    url: `${PRODUCT_IP}${PATH.deletedocuments}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      toast.success('Deleted successfully');
      return true;
    } else {
      toast.error('Something Went Wrong');

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const ZipBatch = (job_id) => async (dispatch) => {
  const data1 = { job_id_list: job_id, user_email: '', e_id };
  const args = {
    method: 'POST',
    url: `${ADMIN_URL}${PATH.zipbatch}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v3.0',
    },
    data: data1,
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data.zipfile_path);
      exportPath(api.data.zipfile_path);
      // window.open(`https://creditdemo.in-d.ai${api.data.Zip_file}`);
      // toast.success("Deleted successfully");
      // return true;
    } else {
      toast.error('Something Went Wrong');

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
export const exportPath = (zippath, key) => {
  console.log(zippath, 'abcdefghydueuefhif');
  try {
    axios(`${PRODUCT_IP}${zippath}`, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
        'Accept-Version': 'v1.0',
      },
    })
      .then((res) => {
        let filePath = zippath.split('/')[zippath.split('/').length - 1];
        if (res.status === 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          if (key === 'JSON') {
            link.setAttribute('download', filePath);
          } else {
            link.setAttribute('download', filePath); //or any other extension
          }
          document.body.appendChild(link);
          link.click();
          console.log(link);
        } else if (res.status === 201) {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error && error.response) {
          if (error.response.status === 401) {
            toast.error(error.response.data.message);

            redirectToLogin();
          }
        } else {
          toast.error('500 Internal Server Error');
        }
      });
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
// Delete Case ID
export const deleteCaseId = async (caseIds) => {
  const args = {
    method: 'POST',
    url: `${PRODUCT_IP}${PATH.delete_caseId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: { b_ids: caseIds, e_id },
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data);
      toast.success(`Successfully deleted`);
      return true;
    } else {
      toast.error(api.data.message);

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

export const handleSearch = (searchTerm) => (dispatch) => {
  console.log('handleSearch', searchTerm);
  dispatch({ type: 'TRACK_SERCH_TERM', payload: searchTerm });
};
export const handleResetSearchTerm = () => (dispatch) => {
  dispatch({ type: 'CLEAR_SERCH_TERM', payload: '' });
};
// * Export as excel / json
export const exportHandling = (batchIds, key) => async (dispatch) => {
  // console.log('exportHandling',batchIds,key)
  let path = key === 'JSON' ? PATH.export_json_case : PATH.export_excel_case;
  const args = {
    method: 'POST',
    url: `${PRODUCT_IP}${path}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v1.0',
    },
    data: { bId_list: batchIds, e_id },
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data);
      toast.success(api.data.message);
      if (key === 'JSON') {

        exportPath(api.data.zipfile_path, 'JSON');

      } else {

        exportPath(api.data.zipfile_path, 'XLSX');

      }

      // return true;
    } else {
      toast.error(api.data.message);

      // return false;
    }
  } catch (error) {
    if (error && error.response) {
      toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      // return false;
    }
  }
};
export const exportCombinedHandle = (caseIds, key) => async (dispatch) => {
  const args = {
    method: 'POST',
    url: `${PRODUCT_IP}${PATH.export_combined}`,

    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      "Accept-Version": ' v1.0',
      "Accept-Attachment": 'False',
      "Accept-Export-Type": key === 'JSON' ? 2 : 1
    },


    data: { bId_list: caseIds, e_id },
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data,'jaidjmdc');
      toast.success(api.data.message);
      if (key === 'JSON') {

        exportPath(api.data.zipfile_path, 'JSON');

      } else {

        exportPath(api.data.zipfile_path, 'XLSX');

      }
      return true;
    } else {
      toast.error(api.data.message);

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};

// * Export as excel
// export const exportAsExcel = (caseIds) => async (dispatch) => {
//   const args = {
//     method: 'POST',
//     url: `${PRODUCT_IP}${PATH.export_caseid_excel}`,
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${token}`,
//       'Accept-Version': 'v3.1',
//     },
//     data: { bId_list: caseIds },
//   };
//   try {
//     let api = await axios(args);
//     if (api.status == 200) {
//       console.log(api.data);
//       toast.success(api.data.message);
//       exportPath(api.data.zipfile_path);
//       return true;
//     } else {
//       toast.error(api.data.message);

//       return false;
//     }
//   } catch (error) {
//     if (error && error.response) {
//       // toast.error(error?.response?.data?.message);
//       if (error.response.status === 401) {
//         redirectToLogin();
//       }
//       return false;
//     }
//   }
// };
// * Unlock pdf
export const unlockPdf = (data, callback) => async (dispatch) => {
  const args = {
    method: 'POST',
    url: `${ADMIN_URL}${PATH.get_password}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v3.0',
    },
    data: {...data, e_id},
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data);
      toast.success(api.data.message);
      callback(api.data, true);
      return true;
    } else {
      callback(api.data, false);
      toast.error(api.data.message);

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      callback(error.response.data, false);
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
// * Recover API
export const recoverCaseId = (data) => async (dispatch) => {
  const args = {
    method: 'POST',
    url: `${PRODUCT_IP}${PATH.recover_caseid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Version': 'v3.0',
    },
    data: {
      b_ids: data,
      e_id
    },
  };
  try {
    let api = await axios(args);
    if (api.status == 200) {
      console.log(api.data);
      toast.success(api.data.message);
      return true;
    } else {
      toast.error(api.data.message);

      return false;
    }
  } catch (error) {
    if (error && error.response) {
      // toast.error(error?.response?.data?.message);
      if (error.response.status === 401) {
        redirectToLogin();
      }
      return false;
    }
  }
};
